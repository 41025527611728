// 
// widgets.scss
//

.member-card {
    .member-thumb {
      position: relative;
    }
    .member-star {
      position: absolute;
      bottom: 10px;
      right: 0px;
      background-color: $card-bg;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      line-height: 26px;
      text-align: center;
      font-size: 18px;
    }
  }

  .social-links{
    li{
        a {
            border-radius: 50%;
            color: rgba($text-muted,0.5);
            display: inline-block;
            height: 30px;
            line-height: 27px;
            border: 2px solid rgba($text-muted,0.5);
            text-align: center;
            width: 30px;
            &:hover{
                color: $text-muted;
                border: 2px solid $text-muted;
            }
    }
  }
}

@media (min-width: 1200px) {
  .widget-inline-box{
    border-right: 1px solid rgba($dark,0.2);
}
}



// social Widgets

.social-feed-box {
  .social-feed-slider {
    padding-bottom: 50px;
  }
  .carousel-indicators {
    bottom: 0;
  }
}


// widget-2

.widget-box-two {
    margin: 30px -24px 2px -24px;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  // widget-3

  .pro-widget-img {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    padding: 70px 0;
    background-size: cover;
    background: url(../images/bg.jpg) center right no-repeat;
}


// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        overflow: hidden;
        padding: 12px 0px;
        position: relative;
        border-bottom: 1px solid $gray-300;

        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;

            img {
                width: 40px;
            }
        }

        .inbox-item-text {
            color: $gray-500;
            display: block;
            margin: 0;
            overflow: hidden;
        }

        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}