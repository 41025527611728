// 
// topbar.scss
//

// Logo
.logo {
    display: block;
    line-height: $topbar-height;
    span.logo-lg {
        display: block;
    }
    span.logo-sm {
        display: none;
    }
    .logo-lg-text-dark {
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }
    .logo-lg-text-light {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }
}

.logo-box {
    height: $topbar-height;
    width: $leftbar-width;
    float: left;
}
.logo-light {
    display: $logo-light-display;
}

.logo-dark {
    display: $logo-dark-display;
}


// User detail
.user-box {
    min-height: 80px;
    padding: 20px;
    position: relative;
  
    img {
      position: relative;
      height: 48px;
      width: 48px;
    }
  
    .user-info {
      color: $dark;
      margin-left: 60px;
      position: relative;
      z-index: 99999;
  
      p {
        margin-bottom: 0;
        font-size: 13px;
      }
  
      a {
        color: $dark;
        display: block;
        font-weight: 600;
        padding-top: 5px;
      }
    }
  }

.navbar-custom {
    padding: 0 10px 0 0;
    left: 0;
    right: 0;
    height: $topbar-height;
    z-index: 100;
    background: $body-bg;

    .topnav-menu {
        > li {
            float: left;
        }
         .nav-link {
            padding: 0 15px;
            color: rgba($dark, 0.7);
            min-width: 32px;
            display: block;
            line-height: $topbar-height;
            text-align: center;
            max-height: $topbar-height;
        }
    }
    .topnav-menu-left{
        overflow: hidden;
    }

    .dropdown.show {
        .nav-link {
            background-color: rgba($white,0.05);
        }   
    }


    /* Search */
    .app-search {
        overflow: hidden;
        height: $topbar-height;
        display: table;
        max-width: 180px;
        margin-right: 20px;
        
        .app-search-box {
            display: table-cell;
            vertical-align: middle;

            input::-webkit-input-placeholder {
                font-size: 0.8125rem;
                color: rgba($dark, 0.8);
            }
        }
        .form-control {
            border: none;
            height: 38px;
            padding-left: 20px;
            padding-right: 0;
            color: $gray-500;
            background-color: darken($card-bg, 1%);
            box-shadow: none;
            border-radius: 30px 0 0 30px;
        }
        .input-group-append {
            margin-left: 0;
            z-index: 4;
        }

        .btn {
            background-color: darken($card-bg, 1%);
            color: rgba($dark, 0.6);
            border-color: transparent;;
            border-radius: 0 30px 30px 0;
            box-shadow: none !important;
        }
    }

    .button-menu-mobile {
        border: none;
        color: rgba($dark, 0.7);
        display: inline-block;
        height: $topbar-height;
        line-height: $topbar-height;
        width: 60px;
        background-color: transparent;
        font-size: 24px;
        cursor: pointer;
    }
    
    .button-menu-mobile.disable-btn {
        display: none;
    }
}


/* Notification */
.noti-scroll {
    max-height: 220px;
}

.notification-list {
    margin-left: 0;

    .noti-title {
        background-color: transparent;
        padding: 15px 20px;
    }

    .noti-icon {
        font-size: 22px;
    }

    .noti-icon-badge {
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        top: 14px;
        right: 12px;
    }

    .notify-item {
        padding: 12px 20px;

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            margin-top: 4px;
            margin-right: 10px;
            border-radius: 50%;
            color: $white;
        }

        .notify-details {
            margin-bottom: 0px;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $font-family-secondary;
            b {
                font-weight: 500;
            }
            small {
                display: block;
            }
            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }

        }
        
        .user-msg {
            margin-left: 45px;
            line-height: 16px;
        }

        .user-msg, .noti-time {
            color: $gray-500;
        }

    }

    .inbox-widget {
        .inbox-item{
            padding: 12px 20px;
        }   
    }

    .profile-dropdown {
        .notify-item {
            padding: 7px 20px;
        }
    }
}

.profile-dropdown {
    min-width: 170px;
    i {
        margin-right: 5px;
        font-size: 16px;
    }
}

.noti-dot {
    position: relative;
    top: -45px;
  }
  
.pulse {
    width: 2px;
    height: 2px;
    border: 3px solid $white;
    border-radius: 30px;
    background-color: $white;
    z-index: 10;
    position: absolute;
    right: 3px;
    top: -6px;
}
  
.dot {
    border: 4px solid $white;
    background: 0 0;
    border-radius: 60px;
    height: 30px;
    width: 30px;
    animation: pulse 3s ease-out;
    animation-iteration-count: infinite;
    position: absolute;
    top: -18px;
    right: -9px;
    z-index: 1;
    opacity: 0;
}

@keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0.0;
    }
    25% {
      transform: scale(0);
      opacity: 0.1;
    }
    50% {
      transform: scale(0.1);
      opacity: 0.3;
    }
    75% {
      transform: scale(0.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 0.0;
    }
  }

.nav-user {
    padding: 0 12px !important;
    img {
        height: 32px;
        width: 32px;
    }
}

// Responsive

@media (min-width: 1025px){
    .navbar-custom {
        .button-menu-mobile{
            margin-left: 8px;
        }
    }
}

// Topbar Navbar light

.topbar-light{
    .navbar-custom {
        .topnav-menu{
            .nav-link {
                color: $gray-600;
                &.waves-effect.waves-light {
                    .waves-ripple{
                        background: rgba($dark, 0.4);
                    }
                }
            }
        }

        .dropdown.show{
            .nav-link{
                background-color: darken($bg-topbar-light, 4%);
            }
        }

        .pulse, .dot {
            border-color: $primary;
        }

        .notification-list {
            .noti-icon-badge{
                border-color: $bg-topbar-light;
            }
        }
    
        .button-menu-mobile{
            color: $gray-600;
        }

    }
}