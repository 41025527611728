
// 
// authentication.scss
//

.authentication-page{
    padding-bottom: 0px;
    &.enlarged {
        min-height: 100px;
    }
}